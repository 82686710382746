import React, { useRef, useState } from "react"
import classNames from "classnames"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Script from "../components/Script"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { Text, Image, GeneralHours } from "../components/Core"
import { Button, ButtonGroup } from "../components/Button"
import { getPostObj } from "../utils/utils"
import SizzleMp4 from "../videos/sizzle_desktop.mp4"
import { Heading } from "../components/Hero"

import PlayPause from "../components/Sizzle/playPause"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

import Seamless from "../components/Seamless"
import Carousel from "../components/carousel/comFeaturedCarousel"
import HorizontalTwoColumns from "../components/Bulma/HorizontalTwoColumns"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import LocationInfo from "../components/LocationInfo"
import { CallToAction } from "../components/CTA"
import OurReviews from "./about/OurReviews"

import useForms from "../hooks/useForms"

const SingleLocation = ({ data, pageContext, location }) => {
  const napRef = useRef()
  const postObj = getPostObj(
    pageContext,
    data.allLocationsJson.nodes[0],
    data.allSpanishLocationsJson.nodes[0]
  )
  const { post, language } = postObj

  const { form } = useForms(
    post.pageData.layout_3.formSection.seamlessShortcode
  )

  const thisLocation = post

  // GET RELATED LOCATIONS
  // Add locationNames in CMS or JSON to relatedLocations array
  //  [
  //    "location name"
  //  ]
  let { relatedLocations } = data.allLocationsJson.nodes[0].pageData.layout_3
  const allLocations = data.allLocationsDetails.nodes

  // search all locations for matching locationNames
  let locationRelations = allLocations.filter(l =>
    relatedLocations.includes(l.locationData.locationName)
  )

  // just making cleaner objects here
  locationRelations = locationRelations.map(l => l.locationData)
  // GET RELATED LOCATIONS

  const { pageData } = thisLocation
  const { locationData } = thisLocation

  let schemaData
  if (language === "en") {
    schemaData = {
      presets: pageData.schemas.presets,
      customSchema: pageData.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaTitle: pageData.metaTitle,
      description: pageData.metaDescription,
      path: location.pathname
    }
  }

  return (
    <SharedStateProvider>
      <Layout language={language} navSpacer>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          pathname={location.pathname}
          schemaData={schemaData}
          lang={language}
        />
        <LayoutThree
          sizzle={SizzleMp4}
          language={language}
          post={post}
          pageData={pageData}
          locationData={locationData}
          relatedLocations={locationRelations}
          hearWhatTheyHaveToSay={data.hearWhatTheyHaveToSay.nodes[0]}
          formEmbed={form.embedUrl}
          napRef={napRef}
        />
      </Layout>
    </SharedStateProvider>
  )
}

/* Passes layout prop to all children */
function LayoutWrapper(props) {
  return (
    <div className={props.layout}>
      {React.Children.map(props.children, (child, i) => {
        if (child) {
          return React.cloneElement(child, {
            // this properties are available as a props in child components
            layout: props.layout
          })
        }
      })}
    </div>
  )
}

function LocationCarousel(props) {
  return (
    <section className="body-section location-carousel">
      <div className="columns">
        <div className="column is-4" />
        <div className="column">
          <h3>
            {props.language === "es"
              ? "Escuche de Nuestros Pacientes"
              : props.reviewCarouselHeading}
          </h3>
        </div>
      </div>

      <Carousel slideID="featuredCarousel" language={props.language} />
      <div className="columns has-text-centered">
        <div className="column">
          <Button contained buttonText="More Reviews" href="/testimonial/" />
        </div>
      </div>
    </section>
  )
}

function FeaturedProcedures(props) {
  return (
    <section className="body-section featured-procedures-section">
      <div className="columns">
        <div className="column is-3" />
        <div className="column has-text-centered-desktop featured-procedures-header">
          {props.featuredProceduresHeading ? (
            <Text as="h2" text={props.featuredProceduresHeading} />
          ) : (
            <h2>Procedures at Our {props.cityName} Office</h2>
          )}
          {props.featuredProceduresBlurb ? (
            <Text className="mb-0" text={props.featuredProceduresBlurb} />
          ) : (
            <p className="mb-0">
              We offer a wide range of oral and maxillofacial surgery
              procedures. To view all of our services, please visit our{" "}
              <a href="/procedures/" title="View all of our procedures">
                oral surgery page
              </a>
              .
            </p>
          )}
        </div>
        <div className="column is-3" />
      </div>
      {props.featuredProcedures.map((item, i) => {
        const featuredProcedure = item

        // let reviews = getXRandomElements(2, getRelatedReviews(props.reviews));

        // Helper functions
        // function getRelatedReviews(allReviews) {
        //   let reviews = [];
        //   for (let i = 0; i < allReviews.length; i++) {
        //     if (allReviews[i].mainProcedureDone === item.procedureName) reviews.push(allReviews[i])
        //   }
        //   return reviews
        // }
        // function getXRandomElements(x, elements) {
        //   let elementsCopy = elements
        //   shuffleArray(elementsCopy)
        //   elementsCopy = elementsCopy.slice(0, x)
        //   return elementsCopy;
        // }

        let colorBack = true

        if (i % 2 === 0 && props.layout === "layout-3") colorBack = false

        const featuredProcedureClasses = classNames(
          "columns is-vcentered featured-procedure joshua-tree-content",
          {
            "reverse-rows": i % 2 === 1 && props.layout === "layout-2",
            "color-back": colorBack
          }
        )

        return (
          <React.Fragment key={i}>
            <div
              className={featuredProcedureClasses}
              key={featuredProcedure.heading}>
              <div className="column is-2" />
              <div className="column">
                <Image publicId={featuredProcedure.imageId} />
              </div>
              <div className="column is-2" />
              <div className="column bottom-col-mobile">
                <Text as="h3" text={featuredProcedure.heading} />
                <Text text={featuredProcedure.text} />
                {/* <ButtonGroupMap hyperlink buttons={featuredProcedure.buttons} /> */}
                <FeaturedProcedureButtons
                  buttons={featuredProcedure.buttons}
                  napRef={props.napRef}
                />
              </div>
              <div className="column is-3" />
            </div>
            {/* <FeaturedProcedureReviews colorBack={colorBack} heading={featuredProcedure.reviewsHeading} reviews={reviews} /> */}
          </React.Fragment>
        )
      })}
    </section>
  )
}

const LocationDetails = ({ location, data, napRef }) => (
  <div className="columns location-details">
    <div className="column is-2" />

    <div className="column">
      <div className="details-blurb">
        <Text text={data.text} />
      </div>

      <div className="nap-list">
        {data.listOne && <Text text={data.listOne} />}
        {data.listTwo && <Text text={data.listTwo} />}
      </div>
    </div>

    <div className="column is-2" />

    <div className="column nap-block">
      <h3 id="nap" ref={napRef}>
        {data.addressHeading}
      </h3>

      <div className="nap-info">
        {!data.showAddress ? (
          ""
        ) : (
          <>
            <a
              href={location.googlePlaceLink}
              target="_blank"
              title={`Get directions to our ${location.locationName} location.`}
              rel="noreferrer">
              {`${location.streetNumber} ${location.streetName}`}
              <div className="address-spacer" />
              {`${location.cityName}, ${location.stateAbbr} ${location.cityZip}`}
            </a>
          </>
        )}

        {!data.showPhone ? (
          ""
        ) : (
          <>
            <a href={`tel:+1${location.phone}`} title="Give us a call">
              {location.phone}
            </a>
          </>
        )}

        {!data.showEmail ? (
          ""
        ) : (
          <>
            <a href={`mailto:${location.email}`} title="Email us">
              {location.email}
            </a>
          </>
        )}

        {!data.imageId ? "" : <Image publicId={data.imageId} />}

        <GeneralHours
          language="en"
          contact
          locationHours={location.locationHours}
        />
      </div>
    </div>

    <div className="column is-2" />
  </div>
)

function Tour({ iframeSrc }) {
  return (
    <div className="columns tour is-centered">
      <div className="column is-4" />
      <div className="column">
        <iframe
          title={iframeSrc}
          style={{ border: "0" }}
          src={iframeSrc}
          width="850"
          height="450"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
        />
      </div>
      <div className="column is-4" />
    </div>
  )
}

function FormSection({ form, embed }) {
  const toCamelCase = string => {
    let out = ""
    string.split(" ").forEach((el, idx) => {
      const add = el.toLowerCase()
      out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1)
    })
    return out
  }

  if (form.hasForm) {
    return (
      <div className="columns location-contact-form">
        <div className="column is-4" />

        <div className="column">
          <center>
            <div style={{ maxWidth: "1000px" }}>
              <div id="form">
                {form.heading && <h3>{form.heading}</h3>}
                <p className="required-fields">
                  <span className="required-asterisk">*</span>
                  Required Fields
                </p>
              </div>
              <Seamless src={embed} />
            </div>
          </center>
        </div>

        <div className="column is-4" />
      </div>
    )
  }
  return ""
}

function MapSection(props) {
  return (
    <section className="body-section">
      <div className="columns has-text-centered">
        <div className="column location-map-heading">
          <h3>{props.heading}</h3>
          <ButtonGroupMap isCentered buttons={props.buttons} />
        </div>
      </div>
      <div className="columns location-map-image">
        <div className="column">
          <Image publicId={props.mapImage} />
        </div>
      </div>
      <div className="columns has-text-centered">
        <div className="column is-4" />
        <div className="column">
          <p className="large">{props.blurb}</p>
        </div>
        <div className="column is-4" />
      </div>
      {/* <div className="columns location-map-bottom">
        <div className="column">
          <ButtonGroupMap isCentered buttons={props.buttons} />
        </div>
      </div> */}
    </section>
  )
}

function LayoutThree(props) {
  const data = { ...props }
  return (
    // <LayoutWrapper layout={data.pageData.layout}>
    <LayoutWrapper layout="layout-3">
      {props.sizzle ? (
        <SizzleHeadingButtons
          language="en"
          cldSizzle={props.sizzle}
          heading={data.pageData.layout_3.sizzleHeadingButtons.heading}
          buttons={data.pageData.layout_3.sizzleHeadingButtons.buttons}
        />
      ) : (
        <Heading
          heading={data.pageData.layout_3.sizzleHeadingButtons.heading}
        />
      )}
      <TextImage
        className="is-vcentered text-image"
        text={data.pageData.layout_3.textImage.text}
        // imageId={data.pageData.layout_3.textImage.imageId}
      />

      {/* Borrowed from About Us page */}
      <OurReviews post={props.hearWhatTheyHaveToSay} />

      <FeaturedProcedures
        featuredProcedures={data.pageData.layout_3.featuredProcedures}
        featuredProceduresHeading={
          data.pageData.layout_3.featuredProceduresHeading
        }
        // reviews={props.reviews}
        featuredProceduresBlurb={data.pageData.layout_3.featuredProceduresBlurb}
        napRef={props.napRef}
        cityName={data.locationData.cityName}
      />

      {/* <LocationDetails
        location={data.locationData}
        data={data.pageData.layout_3.locationDetails}
        napRef={props.napRef}
      /> */}
      <LocationInfo
        data={data.pageData.layout_3.locationDetails}
        location={data.locationData}
        relatedLocations={data.relatedLocations}
        napRef={props.napRef}
        hideAddress={props.post.useOnContact && props.post.useOnFooter}
      />

      {data.pageData.layout_3.iframeSrc && (
        <Tour iframeSrc={data.pageData.layout_3.iframeSrc} />
      )}

      <FormSection
        embed={props.formEmbed}
        form={data.pageData.layout_3.formSection}
      />

      {/* <MapSection
        mapImage={data.pageData.layout_3.mapSection.mapImage}
        heading={data.pageData.layout_3.mapSection.heading}
        blurb={data.pageData.layout_3.mapSection.blurb}
        buttons={data.pageData.layout_3.mapSection.buttons}
      /> */}
      <AllRatingsReviews
        heading="Our Ratings and Reviews From Patients"
        // buttonHref="/testimonial/"
        // buttonText="More Reviews"
        colorBack={data.pageData.layout_3.formSection.hasForm}
        office={data.post.isSilo ? false : data.locationData.locationName}
      />
      {/* <CallToAction
        colorBack={!data.pageData.layout_3.formSection.hasForm}
        heading={data.pageData.layout_3.getStarted.heading}
        blurb={data.pageData.layout_3.getStarted.blurb}
        buttons={data.pageData.layout_3.getStarted.buttons}
      /> */}
    </LayoutWrapper>
  )
}

function SizzleHeadingButtons(props) {
  const vidRef = useRef()
  const [playing, setPlaying] = useState(true)

  function handlePlayPause() {
    if (playing) {
      vidRef.current.pause()
      setPlaying(false)
    } else {
      vidRef.current.play()
      setPlaying(true)
    }
  }

  return (
    <HorizontalTwoColumns
      style={{ paddingTop: "88px" }}
      className="is-vcentered sizzle-heading-buttons"
      leftColWidth={0}
      middleColWidth={1}
      rightColWidth={2}
      leftColumn={
        <div className="column is-12 sizzle">
          <video
            ref={vidRef}
            width="100%"
            height=""
            playsInline
            loop
            muted
            autoPlay
            controls={false}>
            <source src={props.cldSizzle} type="video/mp4" />
          </video>
          <PlayPause
            language={props.language ? props.language : null}
            handlePlayPause={handlePlayPause}
            videoPlaying={playing}
          />
        </div>
      }
      rightColumn={
        <div className="column bottom-col-mobile location-sizzle-heading">
          <h1>{props.heading}</h1>
          <ButtonGroupMap
            isCentered={props.buttons.length > 1}
            buttons={props.buttons}
          />
        </div>
      }
    />
  )
}

function TextImage(props) {
  if (props.imageId) {
    return (
      <HorizontalTwoColumns
        style={{ paddingTop: "88px" }}
        className={props.className}
        leftColWidth={2}
        middleColWidth={1}
        rightColWidth={2}
        leftColumn={
          <Text className="column is-9 location-text-image" text={props.text} />
        }
        rightColumn={
          <div className="column">
            <Image publicId={props.imageId} />
          </div>
        }
      />
    )
  }
  return (
    <div className="columns text-image imageless color-back">
      <div className="column is-2" />
      <div className="column">
        <Text text={props.text} />
        <ButtonGroup isCentered>
          <Button
            href="/procedures/"
            buttonText="Our Procedures"
            contained
            destination="internal"
          />
        </ButtonGroup>
      </div>
      <div className="column is-2" />
    </div>
  )
}

function FeaturedProcedureButtons({ buttons, napRef }) {
  const scrollToElement = id => {
    // napRef.current.scrollIntoView(true, {behavior: "smooth"})
    window.scroll({
      top: napRef.current.offsetTop - 100,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <ButtonGroup>
      {buttons &&
        buttons.map((btn, i) => (
          <React.Fragment key={i}>
            {btn.button.href[0] === "#" ? (
              <a
                className="standard-button contained"
                onClick={() => scrollToElement()}>
                {btn.button.buttonText}
              </a>
            ) : (
              <Button
                contained={btn.button.appearance === "contained"}
                minimal={btn.button.appearance === "minimal"}
                external={btn.button.destination === "external"}
                tel={btn.button.destination === "tel"}
                key={btn.button.href}
                buttonText={btn.button.buttonText}
                href={btn.button.href}
              />
            )}
          </React.Fragment>
        ))}
    </ButtonGroup>
  )
}

export const pageQuery = graphql`
  query SingleLocation($title: String!) {
    allLocationsJson(filter: { pageData: { title: { eq: $title } } }) {
      nodes {
        isSilo
        useOnContact
        useOnFooter
        pageData {
          metaTitle
          metaDescription
          searchHeading
          title
          layout
          schemas {
            presets
            custom
          }
          layout_3 {
            ...layoutThreeFields
            featuredProceduresBlurb
            relatedLocations
            locationDetails {
              heading
              imageId
              listOne
              listTwo
              showAddress
              showEmail
              showPhone
              text
              buttons {
                button {
                  href
                  buttonText
                  appearance
                  destination
                }
              }
            }
            formSection {
              hasForm
              heading
              locationForm
              seamlessShortcode
            }
            getStarted {
              subheading
              heading
              buttonText
              href
              buttons {
                button {
                  appearance
                  destination
                  href
                  buttonText
                }
              }
              blurb
            }
          }
        }
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
          coords {
            lng
            lat
          }
          areaServed
        }
      }
    }
    allSpanishLocationsJson(filter: { pageData: { title: { eq: $title } } }) {
      nodes {
        pageData {
          metaTitle
          metaDescription
          searchHeading
          title
          layout
          layout_3 {
            ...layoutThreeFieldsEs
          }
        }
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
        }
      }
    }
    allLocationsDetails: allLocationsJson {
      nodes {
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
        }
      }
    }

    hearWhatTheyHaveToSay: allUniquePagesJson(
      filter: { hearWhatTheyHaveToSay: { heading: { ne: null } } }
    ) {
      nodes {
        hearWhatTheyHaveToSay {
          heading
          videos {
            patients {
              imageId
              youtube
              caption
            }
            referringProviders {
              imageId
              youtube
              caption
            }
          }
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "about-us/" } }) {
      nodes {
        meetOurDoctors {
          images {
            doctor {
              caption
              imageId
              url
            }
          }
        }
      }
    }
  }
`

export default SingleLocation
